import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { CustomMenu, CustomMenuItem } from '../../../common/StyledComponents';
import * as queries from '../../../../graphql/queries';
import { API } from 'aws-amplify';
import DealItem from '../selectedDeal/DealItem';
import { GamifyIcon } from '../../../components/GamifyIcon';
import { SearchInput } from '../../users/StyledInputs';
import DealsKanbanSkeleton from './DealsKanbanSkeleton.view';
import DealCard from '../DealCard';
import OwnerDealStageModal from '../OwnerDealStageModal';
import { GamifyToast } from '../../../common/CustomToasts';
import fetchDealTypes from '../fetches/fetchDealTypes';
import fetchDealTypeConfigs from '../fetches/fetchDealTypeConfigs';
import fetchDealStages from '../fetches/fetchDealStages';
import fetchPeople from '../../teams/fetchPeople';
import fetchCirclesOrPeople from '../fetches/fetchCirclesOrPeople';
import getUserAvatar from '../../../common/utils/getUserAvatar';
import {
  DealsByDealStage,
  Deal,
  DealType,
  DealTypeConfig,
  DealStage,
} from './DealsKanban.types';
import {
  selectDealTypeId,
  selectDealTypes,
  selectDealTypesConfigMap,
  selectDealTypesStagesMap,
} from '../../../store/reducers/deals';

export default function DealsKanban() {
  const navigate = useNavigate();
  const params = useParams();

  const reduxDealTypeId = useSelector(selectDealTypeId);
  const reduxDealTypes = useSelector(selectDealTypes);
  const reduxDealTypesConfigMap = useSelector(selectDealTypesConfigMap);
  const reduxDealTypesStagesMap = useSelector(selectDealTypesStagesMap);

  const [searchString, setSearchString] = useState<string>('');

  // NOTE: anchorEl is for the sort menus for deal stages
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedDealStageId, setSelectedDealStageId] = useState<string | null>(
    null
  );

  const [userId, setUserId] = useState<string>(
    // @ts-ignore
    global.me.isAdmin
      ? 'all'
      : // @ts-ignore
        global.me.id
  );
  const [selectedDealId, setSelectedDealId] = useState<string | null>(null);
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);

  const [dealTypes, setDealTypes] = useState<{ [id: string]: DealType }>(
    Object.keys(reduxDealTypes).length > 0 ? reduxDealTypes : {}
  );

  const [dealTypesConfigMap, setDealTypesConfigMap] = useState<{
    [id: string]: DealTypeConfig;
  }>(
    Object.values(reduxDealTypesConfigMap).length > 0
      ? reduxDealTypesConfigMap
      : {}
  );

  const [addDealOpen, setAddDealOpen] = useState<boolean>(false);
  const [selectedDealMetaDataOpen, setSelectedDealMetaDataOpen] =
    useState(false);

  const [categoryId, setCategoryId] = useState<string>(reduxDealTypeId || '');
  const [dealStages, setDealStages] = useState<DealStage[] | []>(
    Object.keys(reduxDealTypesStagesMap).length > 0 && reduxDealTypeId
      ? reduxDealTypesStagesMap[reduxDealTypeId]
      : []
  );
  const [normalizedDealStages, setNormalizedDealStages] = useState<any>(
    Object.keys(reduxDealTypesStagesMap).length > 0 && reduxDealTypeId
      ? reduxDealTypesStagesMap[reduxDealTypeId].reduce((acc, dealStage) => {
          // @ts-ignore
          acc[dealStage.id] = dealStage;
          return acc;
        }, {})
      : {}
  );

  const [areDealStagesLoaded, setAreDealStagesLoaded] = useState<boolean>(
    Object.keys(reduxDealTypesStagesMap).length > 0 && reduxDealTypeId !== null
  );
  const [dealPeople, setDealPeople] = useState<any[]>([]);
  const [areDealPeopleLoading, setAreDealPeopleLoading] =
    useState<boolean>(true);

  // Essentially always false so unused state variables (may need once multiple deal types implemented)
  if (Object.keys(dealTypes).length === 1000) {
    console.log(addDealOpen);
  }

  //   Use in useEffect for refetching deals
  const [dealFilter, setDealFilter] = useState<any>({
    dealType: 'all', // TO DO: Is all even possible? Default to first valid dealType in dealTypes if not.
    dealStage: 'all',
    dealOwner: 'all',
  });

  const [showOwnerDealStageModal, setShowOwnerDealStageModal] =
    useState<boolean>(false);

  const initialState: DealsByDealStage =
    Object.keys(reduxDealTypesStagesMap).length > 0 && reduxDealTypeId
      ? reduxDealTypesStagesMap[reduxDealTypeId].reduce((acc, dealStage) => {
          // @ts-ignore
          acc[dealStage.id] = {
            deals: [],
            sortOrder: 'DESC',
            currentOffset: 0,
            canLoadMore: false,
            isLoading: true,
          };
          return acc;
        }, {})
      : {};

  const SET_DEALS = 'SET_DEALS';
  const UPDATE_DEAL_STAGE = 'UPDATE_DEAL_STAGE';

  function dealsReducer(state: DealsByDealStage, action: any) {
    switch (action.type) {
      case SET_DEALS:
        return { ...state, ...action.payload };
      case UPDATE_DEAL_STAGE: {
        const { stageId, updates } = action.payload;
        return {
          ...state,
          [stageId]: {
            ...state[stageId],
            ...updates,
          },
        };
      }
      default:
        return state;
    }
  }

  const [dealsByDealStage, dispatch] = useReducer(dealsReducer, initialState);

  console.log('SORTING dealsByDealStage: ', dealsByDealStage);

  const [triggerSelectedDealRefetch, setTriggerSelectedDealRefetch] =
    useState<number>(0);
  const onTriggerSelectedDealRefetch = () => {
    setTriggerSelectedDealRefetch((refetch) => refetch + 1);
  };

  const handleNewDeal = (e: any) => {
    e.preventDefault();

    // NOTE: If a modal is used there's no need to navigate to /pipeline/new... modal still doesn't work so still navigate
    // setShowNewDealFormModal(true);
    navigate('/pipeline/new', {
      state: {
        isOnKanban: true,
      },
    });
  };

  const handleClearSearch = () => {
    setSearchString('');
  };

  const handleSortClick = (e: any, dealStageId: string) => {
    setAddDealOpen(false);

    setSelectedDealStageId(dealStageId);
    setAnchorEl(e.currentTarget);
  };

  const handleNewestFirstClick = (dealStageId: string) => {
    // const dealStageDeals = dealsByDealStage[dealStageId];

    // const updatedDealStagePayload = {
    //   stageId: dealStageId,
    //   updates: {
    //     deals: dealStageDeals.deals,
    //     sortOrder: 'DESC',
    //     currentOffset: dealStageDeals.currentOffset,
    //     canLoadMore: dealStageDeals.canLoadMore,
    //     isLoading: dealStageDeals.isLoading,
    //   },
    // };

    // dispatch({ type: UPDATE_DEAL_STAGE, payload: updatedDealStagePayload });

    // @ts-ignore
    fetchDeals([dealStageId], 'DESC', true);
  };

  const handleOldestFirstClick = (dealStageId: string) => {
    // const dealStageDeals = dealsByDealStage[dealStageId];

    // const updatedDealStagePayload = {
    //   stageId: dealStageId,
    //   updates: {
    //     deals: dealStageDeals.deals,
    //     sortOrder: 'ASC',
    //     currentOffset: dealStageDeals.currentOffset,
    //     canLoadMore: dealStageDeals.canLoadMore,
    //     isLoading: dealStageDeals.isLoading,
    //   },
    // };

    // dispatch({ type: UPDATE_DEAL_STAGE, payload: updatedDealStagePayload });

    // @ts-ignore
    fetchDeals([dealStageId], 'ASC', true);
  };

  const handleDealSelection = (e: any, dealId: string, deal: Deal) => {
    e.preventDefault();
    setSelectedDealMetaDataOpen(false);
    // setSelectedDealOptionsOpen(false);
    setSelectedDeal(deal);
    navigate(`/pipeline/board/${dealId}`);
  };

  const handleOpenOwnerDealStageModal = () => {
    setShowOwnerDealStageModal(true);
  };

  const handleDealStageChange = (
    dealId: string,
    originalStageId: string,
    currentStageId: string
  ) => {
    const originalDealStageDeals = dealsByDealStage[originalStageId];
    const currentDealStageDeals = dealsByDealStage[currentStageId];

    // Get the original deal arrays for originalStageId and currentStageId to return in case maintainDealOwners fails in OwnerDealStageModal
    const originalStageDeals = originalDealStageDeals.deals;
    const currentStageDeals = currentDealStageDeals.deals;

    // Optimistically update deal with dealId from originalStageId to currentStageId
    const updatedOriginalStageDeals = [...originalStageDeals];
    const updatedCurrentStageDeals = [...currentStageDeals];

    const dealIndex = originalStageDeals.findIndex(
      (deal: Deal) => deal.id === dealId
    );

    // findIndex returns -1 when index not found, return
    if (dealIndex !== -1) {
      // Splice the deal out of the original stage deals copy and add it to the start of the current stage deals copy to dispatch to dealsByDealStage
      const [updatedDeal] = updatedOriginalStageDeals.splice(dealIndex, 1);
      updatedCurrentStageDeals.unshift(updatedDeal);
    } else {
      return [originalStageDeals, currentStageDeals, false];
    }

    const updatedOriginalStagePayload = {
      stageId: originalStageId,
      updates: {
        deals: updatedOriginalStageDeals,
        sortOrder: originalDealStageDeals.sortOrder,
        currentOffset: updatedOriginalStageDeals.length,
        canLoadMore: originalDealStageDeals.canLoadMore,
        isLoading: false,
      },
    };
    const updatedCurrentStagePayload = {
      stageId: currentStageId,
      updates: {
        deals: updatedCurrentStageDeals,
        sortOrder: currentDealStageDeals.sortOrder,
        currentOffset: updatedCurrentStageDeals.length,
        canLoadMore: currentDealStageDeals.canLoadMore,
        isLoading: false,
      },
    };

    dispatch({ type: UPDATE_DEAL_STAGE, payload: updatedOriginalStagePayload });
    dispatch({ type: UPDATE_DEAL_STAGE, payload: updatedCurrentStagePayload });

    return [originalStageDeals, currentStageDeals, true];
  };

  const handleDealStageChangeRevert = (
    originalStageId: string,
    originalStageDeals: Deal[],
    currentStageId: string,
    currentStageDeals: Deal[]
  ) => {
    const originalDealStageDeals = dealsByDealStage[originalStageId];
    const currentDealStageDeals = dealsByDealStage[currentStageId];

    // Dispatch the updates to revert dealsByDealStage to its original state before the failed stage/owner change
    const originalStagePayload = {
      stageId: originalStageId,
      updates: {
        deals: originalStageDeals,
        sortOrder: originalDealStageDeals.sortOrder,
        currentOffset: originalStageDeals.length,
        canLoadMore: originalDealStageDeals.canLoadMore,
        isLoading: false,
      },
    };

    const currentStagePayload = {
      stageId: currentStageId,
      updates: {
        deals: currentStageDeals,
        sortOrder: currentDealStageDeals.sortOrder,
        currentOffset: currentStageDeals.length,
        canLoadMore: currentDealStageDeals.canLoadMore,
        isLoading: false,
      },
    };

    dispatch({ type: UPDATE_DEAL_STAGE, payload: originalStagePayload });
    dispatch({ type: UPDATE_DEAL_STAGE, payload: currentStagePayload });
  };

  const handleRefetchDeals = (
    dealStageIds: string[],
    sortOrder: null | 'DESC' | 'ASC',
    showLoading: boolean
  ) => {
    setTimeout(() => {
      // @ts-ignore
      fetchDeals(dealStageIds, sortOrder, showLoading);
    }, 1000);
  };

  const handleDeleteDeal = (dealId: string, stageId: string) => {
    const dealStageDeals = dealsByDealStage[stageId];
    const deals = dealStageDeals.deals;

    // Filter out the deleted deal before dispatching the update to that stage
    const filteredDeals = deals.filter((deal: Deal) => deal.id !== dealId);

    const deletedDealPayload = {
      stageId,
      updates: {
        deals: filteredDeals,
        sortOrder: dealStageDeals.sortOrder,
        currentOffset: filteredDeals.length,
        canLoadMore: dealStageDeals.canLoadMore,
        isLoading: false,
      },
    };

    dispatch({ type: UPDATE_DEAL_STAGE, payload: deletedDealPayload });
  };

  async function prepareFiltersWithRedux() {
    // @ts-ignore
    const allOption = global.me.isAdmin;
    const useChildCirclesList = true;

    // @ts-ignore
    if (global.me.isAdmin) {
      const tempPeople = await fetchPeople('', allOption);
      setDealPeople(tempPeople || []);
    } else {
      let pickUsers, pickCircles, useCirclesList;

      // @ts-ignore
      if (!global.appSettings.features.deal.disableUserFilter) {
        pickUsers = true;
      }
      // @ts-ignore
      if (!global.appSettings.features.deal.disableCircleFilter) {
        pickCircles = true;
        useCirclesList = true;
      }

      const tempCirclesOrPeople = await fetchCirclesOrPeople(
        '',
        allOption,
        pickUsers,
        pickCircles,
        useCirclesList,
        useChildCirclesList
      );
      setDealPeople(tempCirclesOrPeople || []);
    }

    setAreDealPeopleLoading(false);
  }

  async function prepareFiltersAndMapsWithoutRedux() {
    // NOTE: getDealTypes sets default deal type in dealFilter's type key and sets categoryID to the defaultCategoryID when more than one possible deal type
    const [tempDefaultCategoryID, tempDealTypes] = await fetchDealTypes();

    const tempDealTypesConfigMap: any = await fetchDealTypeConfigs(
      Object.values(tempDealTypes)
    );

    const tempDealStages = await fetchDealStages(
      tempDefaultCategoryID,
      true, // showAll
      false, // activeOptiom
      false, // allOption
      null, // minSequence
      true // ignoreNoDealStages
    );

    // Set up dealStages in dealsByDealStage as loading until lambda called for each dealStage
    const tempDealsByDealStage = {};

    for (const dealStage of tempDealStages) {
      // @ts-ignore
      tempDealsByDealStage[dealStage.id] = {
        deals: [],
        sortOrder: 'DESC',
        currentOffset: 0,
        canLoadMore: false,
        isLoading: true,
      };
    }

    dispatch({ type: SET_DEALS, payload: tempDealsByDealStage });

    // @ts-ignore
    const allOption = global.me.isAdmin;
    const useChildCirclesList = true;

    // @ts-ignore
    if (global.me.isAdmin) {
      const tempPeople = await fetchPeople('', allOption);
      setDealPeople(tempPeople || []);
    } else {
      let pickUsers, pickCircles, useCirclesList;

      // @ts-ignore
      if (!global.appSettings.features.deal.disableUserFilter) {
        pickUsers = true;
      }
      // @ts-ignore
      if (!global.appSettings.features.deal.disableCircleFilter) {
        pickCircles = true;
        useCirclesList = true;
      }

      const tempCirclesOrPeople = await fetchCirclesOrPeople(
        '',
        allOption,
        pickUsers,
        pickCircles,
        useCirclesList,
        useChildCirclesList
      );
      setDealPeople(tempCirclesOrPeople || []);
    }

    const tempNormalizedDealStages = tempDealStages.reduce(
      (acc: any, stage: any) => {
        acc[stage.id] = stage;
        return acc;
      },
      {}
    );

    // Set the dealType filter based on defaultCategoryID
    for (const dealType of Object.values(tempDealTypes)) {
      // @ts-ignore
      if (dealType.id === tempDefaultCategoryID) {
        setDealFilter((p: any) => ({
          ...p,
          // @ts-ignore
          dealType: dealType.title,
        }));
        break;
      }
    }

    setCategoryId(tempDefaultCategoryID);
    setDealTypes(tempDealTypes);
    setDealTypesConfigMap(tempDealTypesConfigMap);
    // @ts-ignore
    setDealStages(tempDealStages);
    setNormalizedDealStages(tempNormalizedDealStages);
    setAreDealStagesLoaded(true);
    setAreDealPeopleLoading(false);
  }

  // If no dealStageIds passed into fxn, defaults to all dealStageIds
  // showLoading defaults to true (filtering/searching), but should be set to false for changing deal stages (optimistic loading)
  async function fetchDeals(
    dealStageIds = dealStages.map((dealStage) => dealStage.id),
    sortOrder = null,
    showLoading = true
  ) {
    const displayFields =
      // @ts-ignore
      dealTypesConfigMap[categoryId].pickerDisplayFields;

    // Update deals for each stage
    for (const dealStageId of dealStageIds) {
      const dealStageDeals = dealsByDealStage[dealStageId];

      // NOTE: If the dealStage column isn't loading already and showLoading is true, update it so it is
      if (!dealsByDealStage[dealStageId].isLoading && showLoading) {
        const dealsByDealStageLoadingPayload = {
          stageId: dealStageId,
          updates: {
            deals: [],
            sortOrder: sortOrder || dealStageDeals.sortOrder,
            currentOffset: 0,
            canLoadMore: false,
            isLoading: true,
          },
        };

        dispatch({
          type: UPDATE_DEAL_STAGE,
          payload: dealsByDealStageLoadingPayload,
        });
      }

      let deals = [];
      let canLoadMore;

      try {
        const res = await API.graphql({
          query: queries.listAllDeals,
          variables: {
            request: JSON.stringify({
              categoryID: categoryId,
              stageID: dealStageId,
              assigneeID:
                dealFilter.dealOwner === 'all' ? '' : dealFilter.dealOwner,
              searchString,
              sortOrder: sortOrder || dealStageDeals.sortOrder,
              limit: 25,
              displayFields,
            }),
          },
        });
        // @ts-ignore
        const parsedData = JSON.parse(res.data.listAllDeals);
        deals = parsedData.deals || [];
        canLoadMore = parsedData.canLoadMore;
      } catch (err) {
        console.error(err);

        GamifyToast.error(
          `There was an error loading more deals for the ${normalizedDealStages[dealStageId].name} stage.`
        );

        // Stop the loading animation, set deals to empty array
        const dealsByDealStageStopLoadingPayload = {
          stageId: dealStageId,
          updates: {
            deals: [],
            sortOrder: sortOrder || dealStageDeals.sortOrder || 'DESC',
            currentOffset: 0,
            canLoadMore: false,
            isLoading: false,
          },
        };

        dispatch({
          type: UPDATE_DEAL_STAGE,
          payload: dealsByDealStageStopLoadingPayload,
        });
      }

      const dealsArr: Deal[] = [];

      for (const deal of deals) {
        // Need to get setter/closer profile pictures from imageName (if no imageName, will be an empty string)
        const setterProfilePicture = await getUserAvatar(
          deal.setter.imageName,
          '',
          true
        );
        // @ts-ignore
        deal.setter.profilePicture = setterProfilePicture;

        let closerProfilePicture;

        if (deal.closer) {
          closerProfilePicture = await getUserAvatar(
            deal.closer.imageName,
            '',
            true
          );
          // @ts-ignore
          deal.closer.profilePicture = closerProfilePicture;
        }

        const tempLocalDate = new Date(deal.updatedAt);
        // NOTE: getTimezoneOffset gives the offset from UTC in minutes, converting to ms by multiplying by 60000 is needed
        const timezoneOffset = tempLocalDate.getTimezoneOffset() * 60000;
        // NOTE: timezoneOffset is positive for west of UTC, negative for east of UTC -> subtract this offset to get local time
        const adjustedDate = new Date(tempLocalDate.getTime() - timezoneOffset);
        // NOTE: toISOString converts the Date object to UTC, since adjustedDate was offset this conversion will make adjustedUpdatedAt the user's local date time
        const adjustedUpdatedAt = adjustedDate.toISOString();

        const unformattedUpdatedAt = adjustedUpdatedAt.split('T');
        const dealYear = unformattedUpdatedAt[0].slice(0, 4);
        const dealMonth = unformattedUpdatedAt[0].slice(5, 7);
        const dealDay = unformattedUpdatedAt[0].slice(8, 10);
        const dealHour = unformattedUpdatedAt[1].slice(0, 2);
        const dealMinute = unformattedUpdatedAt[1].slice(3, 5);
        const updatedAt = `${dealMonth}/${dealDay}/${dealYear} ${dealHour}:${dealMinute}`;

        deal.updatedAt = updatedAt;

        dealsArr.push(deal);
      }

      const dealsByDealStageUpdatePayload = {
        stageId: dealStageId,
        updates: {
          deals: dealsArr,
          sortOrder: sortOrder || dealStageDeals.sortOrder,
          currentOffset: dealsArr.length,
          canLoadMore,
          isLoading: false,
        },
      };

      dispatch({
        type: UPDATE_DEAL_STAGE,
        payload: dealsByDealStageUpdatePayload,
      });
    }
  }

  const handleLoadMore = async (dealStageId: string) => {
    const dealStageDeals = dealsByDealStage[dealStageId];
    const currentDeals = dealStageDeals.deals;
    const currentOffset = dealStageDeals.currentOffset;
    const sortOrder = dealStageDeals.sortOrder;

    // Start the loading
    const dealsByDealStageLoadingPayload = {
      stageId: dealStageId,
      updates: {
        deals: currentDeals,
        sortOrder,
        currentOffset,
        canLoadMore: true,
        isLoading: true,
      },
    };

    dispatch({
      type: UPDATE_DEAL_STAGE,
      payload: dealsByDealStageLoadingPayload,
    });

    const displayFields =
      // @ts-ignore
      dealTypesConfigMap[categoryId].pickerDisplayFields;
    let deals = [];
    let canLoadMore;

    try {
      const res = await API.graphql({
        query: queries.listAllDeals,
        variables: {
          request: JSON.stringify({
            categoryID: categoryId,
            stageID: dealStageId,
            assigneeID:
              dealFilter.dealOwner === 'all' ? '' : dealFilter.dealOwner,
            searchString,
            limit: 25,
            offset: currentOffset,
            sortOrder,
            displayFields,
          }),
        },
      });
      // @ts-ignore
      const parsedData = JSON.parse(res.data.listAllDeals);
      deals = parsedData.deals || [];
      canLoadMore = parsedData.canLoadMore;
    } catch (err) {
      console.error(err);

      GamifyToast.error(
        'There was an error loading more deals for this stage.'
      );

      // Stop the loading animation, put back the deals that were there before attempting to load more
      const dealsByDealStageStopLoadingPayload = {
        stageId: dealStageId,
        updates: {
          deals: currentDeals,
          sortOrder,
          currentOffset,
          canLoadMore: false,
          isLoading: false,
        },
      };

      dispatch({
        type: UPDATE_DEAL_STAGE,
        payload: dealsByDealStageStopLoadingPayload,
      });
    }

    const dealsArr: Deal[] = [...currentDeals];

    for (const deal of deals) {
      // Need to get setter/closer profile pictures from imageName (if no imageName, will be an empty string)
      const setterProfilePicture = await getUserAvatar(
        deal.setter.imageName,
        '',
        true
      );
      // @ts-ignore
      deal.setter.profilePicture = setterProfilePicture;

      let closerProfilePicture;

      if (deal.closer) {
        closerProfilePicture = await getUserAvatar(
          deal.closer.imageName,
          '',
          true
        );
        // @ts-ignore
        deal.closer.profilePicture = closerProfilePicture;
      }

      const tempLocalDate = new Date(deal.updatedAt);
      // NOTE: getTimezoneOffset gives the offset from UTC in minutes, converting to ms by multiplying by 60000 is needed
      const timezoneOffset = tempLocalDate.getTimezoneOffset() * 60000;
      // NOTE: timezoneOffset is positive for west of UTC, negative for east of UTC -> subtract this offset to get local time
      const adjustedDate = new Date(tempLocalDate.getTime() - timezoneOffset);
      // NOTE: toISOString converts the Date object to UTC, since adjustedDate was offset this conversion will make adjustedUpdatedAt the user's local date time
      const adjustedUpdatedAt = adjustedDate.toISOString();

      const unformattedUpdatedAt = adjustedUpdatedAt.split('T');
      const dealYear = unformattedUpdatedAt[0].slice(0, 4);
      const dealMonth = unformattedUpdatedAt[0].slice(5, 7);
      const dealDay = unformattedUpdatedAt[0].slice(8, 10);
      const dealHour = unformattedUpdatedAt[1].slice(0, 2);
      const dealMinute = unformattedUpdatedAt[1].slice(3, 5);
      const updatedAt = `${dealMonth}/${dealDay}/${dealYear} ${dealHour}:${dealMinute}`;

      deal.updatedAt = updatedAt;

      dealsArr.push(deal);
    }

    const dealsByDealStageUpdatePayload = {
      stageId: dealStageId,
      updates: {
        deals: dealsArr,
        sortOrder,
        currentOffset: dealsArr.length,
        canLoadMore,
        isLoading: false,
      },
    };

    dispatch({
      type: UPDATE_DEAL_STAGE,
      payload: dealsByDealStageUpdatePayload,
    });
  };

  const cancelIconAdornment =
    searchString.length > 0 ? (
      <Button
        className={'clear-search-button'}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={handleClearSearch}
        disableRipple={true}
      >
        <InputAdornment position="end">
          <GamifyIcon
            icon={'delete'}
            color={'white'}
            backgroundColor={'#D0D2D8'}
            height={24}
            width={24}
          />
        </InputAdornment>
      </Button>
    ) : (
      <></>
    );

  // Default value for Owner filter
  let defaultOwner = '';
  // @ts-ignore
  if (global.me.isAdmin) {
    // @ts-ignore
    defaultOwner = 'All ' + global.appSettings.labels.person.plural;
  } else {
    // @ts-ignore
    defaultOwner = global.me.name;
  }

  useEffect(() => {
    // @ts-ignore
    setSelectedDealId(params.dealId);
  }, [params?.dealId]);

  useEffect(() => {
    if (
      reduxDealTypeId &&
      Object.keys(reduxDealTypes).length > 0 &&
      Object.keys(reduxDealTypesConfigMap).length > 0 &&
      Object.keys(reduxDealTypesStagesMap).length > 0
    ) {
      prepareFiltersWithRedux();
    } else {
      prepareFiltersAndMapsWithoutRedux();
    }
  }, []);

  useEffect(() => {
    if (
      dealStages.length > 0 &&
      Object.keys(dealTypes).length > 0 &&
      categoryId
    ) {
      // NOTE: No need for delay on fetch if searchString is empty string as user isn't typing in a search string but reather clearing the search
      if (searchString !== '') {
        const fetchSearchedDeals = setTimeout(fetchDeals, 1000);

        return () => clearTimeout(fetchSearchedDeals);
      } else {
        fetchDeals();
      }
    } else {
      console.error(
        'Missing either dealStages, dealTypes, or categoryId: ',
        dealStages,
        dealTypes,
        categoryId
      );
    }
  }, [dealStages, dealTypes, dealFilter, searchString]);

  if (!areDealStagesLoaded) {
    return (
      <DealsKanbanSkeleton
        pipelineTitle={dealTypes[categoryId]?.title}
      ></DealsKanbanSkeleton>
    );
  }

  return (
    <>
      {selectedDeal && (
        <OwnerDealStageModal
          deal={selectedDeal}
          isOnKanban={true}
          dealStages={dealStages}
          dealPeople={dealPeople}
          showOwnerDealStageModal={showOwnerDealStageModal}
          setShowOwnerDealStageModal={setShowOwnerDealStageModal}
          fetchDeals={fetchDeals}
          handleDealStageChange={handleDealStageChange}
          handleDealStageChangeRevert={handleDealStageChangeRevert}
          triggerSelectedDealRefetch={triggerSelectedDealRefetch}
          onTriggerRefetch={onTriggerSelectedDealRefetch}
        ></OwnerDealStageModal>
      )}

      {/* NOTE: The menu below is the sort menu for a deal stage column, can eventually be split into a separate component to shorten this file */}
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e: any) => {
          e.stopPropagation();
          setAnchorEl(null);
          setSelectedDealId(null);
        }}
      >
        <CustomMenuItem
          sx={{
            background: 'white',
            color:
              // @ts-ignore
              dealsByDealStage[selectedDealStageId]?.sortOrder === 'DESC'
                ? '#FF6A00'
                : 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e: any) => {
            e.stopPropagation();

            handleNewestFirstClick(selectedDealStageId || '');
            setAnchorEl(null);
          }}
          disableRipple={true}
        >
          Newest first
        </CustomMenuItem>
        <CustomMenuItem
          sx={{
            background: 'white',
            color:
              // @ts-ignore
              dealsByDealStage[selectedDealStageId]?.sortOrder === 'ASC'
                ? '#FF6A00'
                : 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e: any) => {
            e.stopPropagation();

            handleOldestFirstClick(selectedDealStageId || '');
            setAnchorEl(null);
          }}
          disableRipple={true}
        >
          Oldest first
        </CustomMenuItem>
      </CustomMenu>

      <Box style={{ display: 'flex' }}>
        <Box
          style={{
            height: '100vh',
            width: selectedDealId ? '55%' : '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '178px',
            // paddingTop: '88px',
            // backgroundColor: '#F0F0F3',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              position: 'relative',
              width: '100%',
              paddingLeft: 60,
              paddingRight: 60,
            }}
          >
            <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography
                style={{
                  paddingTop: '44px',
                  fontSize: '36px',
                  fontWeight: 700,
                }}
              >
                {`${dealTypes[categoryId].title} Pipeline`}
              </Typography>
            </Box>

            <Button
              disableRipple={true}
              onClick={
                (e) => handleNewDeal(e)
                // TO DO: Put back in once dealTypes implemented
                // dealTypes.length > 1
                //   ? handleAddDealClick()
                //   : handleNewDeal(
                //       e,
                //       dealTypes[0],
                //       dealTypesCategoryIdMap,
                //       dealTypesConfigMap
                //     )
              }
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                backgroundColor: '#FF6A00',
                color: 'white',
                fontSize: '18px',
                fontWeight: 700,
                width: '154px',
                height: '48px',
                padding: '12px 16px 12px 16px',
                borderRadius: '8px',
                gap: '10px',
              }}
            >
              <GamifyIcon
                icon={'add'}
                color={'white'}
                backgroundColor={'#FF6A00'}
                width={24}
                height={24}
              />
              New deal
            </Button>
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              marginTop: 12,
              overflowX: 'auto',
              overflowY: 'hidden',
              height: 'fit-content',
              minHeight: 'fit-content',
            }}
          >
            <Box
              style={{
                paddingLeft: 60,
                display: 'grid',
                columnGap: '16px',
                minWidth: 350,
                // minWidth: 225,
                marginRight: 16,
              }}
              gridTemplateColumns={
                'repeat(1, minmax(0, 1fr))'
                // dealTypes.length > 1
                //   ? 'repeat(3, minmax(0, 1fr))'
                //   : 'repeat(2, minmax(0, 1fr))'
              }
            >
              {/* TO DO: Put back in once dealTypes is implemented */}
              {/* {dealTypes.length > 1 && (
              <Box className={'deal-list-settings-container'}>
                <Typography className={'deal-settings-title-text'}>
                  Type
                </Typography>
                <StyledSelect
                  // style={{ maxWidth: '157px' }}
                  // placeholder="HELLO!!"
                  onChange={(e) => handleDealFilterChange(e)}
                  value={dealFilter.dealType}
                  name="dealType"
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        boxShadow: '0px 4px 24px 0px #ababab5e;',
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        paddingTop: '0px',
                        marginTop: '8px',
                      },
                    },
                  }}
                >
                  {dealTypes.map((opt, i) => (
                    <StyledMenuItem value={opt} key={i}>
                      {capitalizeFirstLetter(opt)}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </Box>
            )} */}
              <Box className={'deal-list-settings-container'}>
                <Typography className={'deal-settings-title-text'}>
                  Who
                </Typography>
                {areDealPeopleLoading ? (
                  <Box className={'deal-settings-dropdown-skeleton-container'}>
                    <Skeleton
                      variant={'rounded'}
                      width={'100%'}
                      height={23}
                    ></Skeleton>
                  </Box>
                ) : (
                  <Box>
                    <Autocomplete
                      id={'deal-autocomplete'}
                      classes={{
                        inputRoot: 'scorecard-dropdown-box',
                        paper: 'autocomplete-menu',
                      }}
                      sx={{ width: 270 }}
                      onChange={(e, val) => {
                        if (userId !== val.value) {
                          setDealFilter((p: any) => ({
                            ...p,
                            dealOwner: val.value,
                          }));
                          setUserId(val.value);
                        }
                      }}
                      options={dealPeople}
                      defaultValue={defaultOwner}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              style={{
                paddingRight: 60,
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <Box
                style={{
                  width: '100% !important',
                  height: '46px',
                  borderRadius: '8px',
                  background: 'white',
                  marginBottom: 2,
                }}
              >
                <SearchInput
                  style={{ minWidth: 502, width: '100%', marginBottom: 0 }}
                  id={'leadSearch'}
                  name={'leadSearch'}
                  placeholder={'Search'}
                  fullWidth
                  onChange={(key) => {
                    // @ts-ignore
                    setSearchString(key?.nativeEvent?.target?.value);
                  }}
                  value={searchString}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position={'start'}
                        sx={{ paddingLeft: '0' }}
                      >
                        <GamifyIcon
                          icon={'search'}
                          color={'#868686'}
                          backgroundColor={'transparent'}
                          height={24}
                          width={24}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: cancelIconAdornment,
                  }}
                  InputLabelProps={{
                    shrink: false,
                    sx: { marginLeft: '24px' },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              position: 'relative',
              width: '100%',
              paddingLeft: 60,
              paddingRight: 60,
              paddingTop: 30,
              overflowX: 'auto',
              overflowY: 'hidden',
            }}
          >
            {/* NOTE: The Boxes below add the 60px padding on either side of the kanban to make it look better when horizontally scrolling */}
            <Box
              style={{
                position: 'fixed',
                top: 180,
                left: 0,
                width: 60,
                height: '100%',
                // backgroundColor: '#F0F0F3',
                backgroundColor: 'white',
                pointerEvents: 'none', // Ensure it doesn't interfere with scrolling or interaction
                zIndex: 1,
              }}
            />

            <Box
              style={{
                position: 'fixed',
                top: 180,
                right: selectedDealId ? '45%' : 0,
                width: 60,
                height: '100%',
                // backgroundColor: '#F0F0F3',
                backgroundColor: 'white',
                pointerEvents: 'none', // Ensure it doesn't interfere with scrolling or interaction
                zIndex: 1,
              }}
            />

            {dealStages.map((dealStage, i) => (
              <Box
                key={i}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  //   backgroundColor: '#ECEDEC',
                  backgroundColor: '#F0F0F3',
                  minWidth: 450,
                  width: 450,
                  padding: 16,
                  marginRight: 16,
                  boxSizing: 'content-box',
                  borderRadius: 8,
                  //   paddingLeft: 8,
                  //   paddingRight: 8,
                  //   borderLeft: i === 0 ? '1px solid #D0D2D8' : 'none',
                  //   borderRight: '1px solid #D0D2D8',
                }}
              >
                <Box
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Box style={{ display: 'flex' }}>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        marginBottom: 16,
                        marginRight: 8,
                      }}
                    >
                      {dealStage.label}
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 24,
                        width:
                          dealsByDealStage[dealStage.id]?.deals?.length >= 1000
                            ? 36
                            : 24,
                        backgroundColor: 'white',
                        borderRadius: 1000,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 13,
                          fontWeight: 700,
                        }}
                      >
                        {dealsByDealStage[dealStage.id]?.deals?.length}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    {dealsByDealStage[dealStage.id]?.deals?.length > 1 && (
                      <Button
                        disableRipple={true}
                        onClick={(e) => handleSortClick(e, dealStage.id)}
                        className={'sort-deals-button'}
                      >
                        <GamifyIcon
                          icon={'sort'}
                          color={'#FF6A00'}
                          backgroundColor={''}
                          height={24}
                          width={24}
                        ></GamifyIcon>
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: 'calc(100% - 40px)',
                    overflowY: 'auto',
                  }}
                >
                  {!dealsByDealStage[dealStage.id]?.isLoading &&
                    dealsByDealStage[dealStage.id]?.deals?.length === 0 && (
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <Typography>
                          There are no deals for this stage.
                        </Typography>
                      </Box>
                    )}
                  {dealsByDealStage[dealStage.id]?.isLoading &&
                  dealsByDealStage[dealStage.id]?.deals?.length === 0 ? (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '50vh',
                      }}
                    >
                      <CircularProgress
                        sx={{ color: 'black' }}
                      ></CircularProgress>
                    </Box>
                  ) : (
                    // @ts-ignore
                    dealsByDealStage[dealStage.value].deals.map((deal, i) => (
                      <DealCard
                        key={i}
                        isOnKanban={true}
                        deal={deal}
                        selectedDealId={selectedDealId}
                        handleDealSelection={handleDealSelection}
                        setAddDealOpen={setAddDealOpen}
                        handleOpenOwnerDealStageModal={
                          handleOpenOwnerDealStageModal
                        }
                        stageId={dealStage.id}
                        handleDeleteDeal={handleDeleteDeal}
                        userId={userId}
                        categoryId={categoryId}
                      ></DealCard>
                    ))
                  )}

                  {dealsByDealStage[dealStage.id].canLoadMore && (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 12,
                      }}
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLoadMore(dealStage.id);
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          backgroundColor: '#FF6A00',
                          width: '154px',
                          height: '48px',
                          padding: '12px 16px 12px 16px',
                          borderRadius: '8px',
                        }}
                        sx={{
                          cursor: dealsByDealStage[dealStage.id].isLoading
                            ? 'not-allowed'
                            : 'cursor',
                        }}
                        disableRipple={true}
                        disabled={dealsByDealStage[dealStage.id].isLoading}
                      >
                        {dealsByDealStage[dealStage.id].isLoading ? (
                          <CircularProgress
                            style={{ height: 24, width: 24 }}
                            sx={{ color: 'white' }}
                          ></CircularProgress>
                        ) : (
                          <Typography
                            style={{
                              color: 'white',
                              fontSize: '16px',
                              fontWeight: 500,
                            }}
                          >
                            Load More
                          </Typography>
                        )}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {selectedDealId && (
          <Box
            style={{
              height: '100vh',
              width: '45%',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '178px',
              // paddingTop: '88px',
              backgroundColor: 'white',
            }}
          >
            <Box className={'deal-selected-card'}>
              <Box style={{ position: 'absolute', top: 188, right: 24 }}>
                <Button
                  className={'ellipsis-button'}
                  disableRipple={true}
                  onClick={() => {
                    setSelectedDealId(null);
                    setSelectedDeal(null);
                    navigate('/pipeline/board');
                  }}
                >
                  <GamifyIcon
                    icon={'x'}
                    color={'white'}
                    backgroundColor={''}
                    height={12}
                    width={12}
                  />
                </Button>
              </Box>
              <DealItem
                // className={'deal-selected-card-item'}
                props={{
                  dealId: selectedDealId,
                  searchUserId: userId,
                }}
                stateProps={{
                  isOnKanban: true,
                  setAddDealOpen,
                  selectedDealMetaDataOpen,
                  setSelectedDealMetaDataOpen,
                  handleOpenOwnerDealStageModal,
                  handleRefetchDeals,
                  handleDeleteDeal,
                  triggerSelectedDealRefetch,
                  onTriggerSelectedDealRefetch,
                  userId,
                  categoryId,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
